import Landing from "@/components/sharedComponents/landing/landing.component";
import { LandingItem } from "@/interfaces/landingItem";
import { EventBus } from "@/internal";
import { PageNavAction } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./PricingView.template.vue";

@Component({
  mixins: [Template],
  components: {
    Landing
  }
})
export default class PricingViewComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public landingOptions: LandingItem[] = [
    {
      namedRoute: "tax-category-list",
      translationString: "tax_categories"
    },
    {
      namedRoute: "pricing-options",
      translationString: "pricing_options"
    },
    {
      namedRoute: "pricing-points",
      translationString: "price_points"
    },
    {
      namedRoute: "batch-level-list",
      translationString: "batch_level_pricing.title"
    },
    {
      namedRoute: "member-level-list",
      translationString: "member_level_pricing.title"
    },
    {
      namedRoute: "price-groups",
      translationString: "price_groups.title"
    }
  ];
  public goTo(route: string) {
    this.$router.push(route);
  }
  public notImplementedYet() {
    EventBus.$emit("notify", { text: "not_implemented_yet", color: "warning" });
  }

  public mounted() {
    this.setPageNav({ title: "batches.pricing_tools" });
  }
}
